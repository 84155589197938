import React, { useEffect, useContext } from "react";
import NavBar from "../components/NavBar";
import Company from "../components/Experience/Company";
import { experience } from "../Data/experience";
import Loader from "../components/Loader/Loader";
import loaderContext from "../context/loaderContext";

const Experience = () => {
  const context = useContext(loaderContext);
  const { loading, setLoading } = context;

  useEffect(() => {
    document.title = "Experience | Utkarsh Saini";
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <div
      className="container-fluid d-flex flex-column align-items-stretch"
      style={{ padding: "1rem" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="navbar-container mt-2 ms-4"
            style={{ fontSize: "1.5rem" }}
          >
            <NavBar />
          </div>
          <div className="container mt-4 p-8">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-sm-12">
                <h2 className="text-left">Experience</h2>
                <div className="text-justify">
                  {experience.map((exp, i) => (
                    <div key={exp.id} className="mb-2">
                      <Company
                        key={exp.id}
                        role={exp.role}
                        company={exp.company}
                        type={exp.type}
                        location={exp.location}
                        logo={exp.logo}
                        date={exp.date}
                        description={exp.description}
                        delay={(i + 1) / 3}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Experience;
