import React, { useEffect, useContext } from "react";
import NavBar from "../components/NavBar";
import certifications from "../Data/certifications";
import Certificate from "../components/Certification/Certificate";
import Loader from "../components/Loader/Loader";
import loaderContext from "../context/loaderContext";

const Certifications = () => {
  const context = useContext(loaderContext);
  const { loading, setLoading } = context;

  useEffect(() => {
    document.title = "Certification | Utkarsh Saini";
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", padding: "1rem" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
            <NavBar />
          </div>
          <div className="container mt-4 p-8" style={{ alignSelf: "center" }}>
            <div className="container mt-4 p-8">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-sm-12">
                  <h2 className="text-left">Certification</h2>
                  <div className="text-justify">
                    {certifications.map((certi, i) => (
                    <div key={certi.id} className="mb-2">
                      <Certificate
                        key={certi.id}
                        title={certi.title}
                        description={certi.description}
                        certificate={certi.certificate}
                        date={certi.date}
                        link={certi.link}
                        delay={(i + 1) / 3}
                        />
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Certifications;
