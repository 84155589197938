import PadhaiHelp from "../images/padhai help logo.png"
import CodeClause from "../images/CodeClause.png"
import RSL from "../images/raja_software_labs.png"

export const experience = [
    {
        id: 3,
        role: "Software Engineer",
        company: "Raja Software Labs",
        type: "Full Time",
        location: "Pune",
        date: "Aug 2024 - Present",
        logo: RSL,
        description: [
            "Developed cross-platform mobile applications using React Native, providing a consistent user experience across iOS and Android.",
            "Designed and managed scalable infrastructure with Pulumi, AWS, and Terraform for seamless, resilient deployments.",
            "Integrated Datadog for comprehensive monitoring and logging, enabling optimized system performance and real-time insights.",
            "Configured Kubernetes and Docker to streamline containerized applications and improve deployment efficiency.",
            "Leveraged Crossplane for infrastructure management, simplifying operations and ensuring efficient cloud resource provisioning."
        ]
    },
    {
        id: 2,
        role: "Web Development",
        company: "CodeClause",
        type: "Internship",
        location: "Remote",
        date: "Jul 2023 - Jul 2023",
        logo: CodeClause,
        description: [
            
        ]
    },
    {
        id: 1,
        role: "Full Stack Developer",
        company: "Padhai help pvt. ltd.",
        type: "Internship",
        location: "Remote",
        date: "May 2023 - May 2023",
        logo: PadhaiHelp,
        description: [
            "Developed responsive front-end interfaces with HTML, CSS, and React.js",
            "Implemented efficient back-end solutions using Node.js",
            "Managed MongoDB database for optimized storage and retrieval."
        ]
    },

]

